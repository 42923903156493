<template>
  <div>
    <v-card class="mb-5" outlined color="white">
      <v-breadcrumbs :items="items" large></v-breadcrumbs>
    </v-card>

    <div>
      <v-card class="pa-5">
        <v-card-title class="primary--text">
          TENTANG INSPEKTORAT
        </v-card-title>

        <v-card-text v-html="item.profil_konten">
          {{ item.profil_konten }}
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    item: {},

    items: [
      {
        text: "Home",
        disabled: false,
        href: "/",
      },
      {
        text: "Profil",
        disabled: true,
        href: "",
      },
      {
        text: "Tentang Inspektorat",
        disabled: false,
        href: "/profil/tentang",
      },
    ],
  }),

  async mounted() {
    this.getData();
  },

  methods: {
    getData() {
      this.http
        .get(process.env.VUE_APP_API_BASE + "profil/tentang")
        .then((res) => {
          this.item = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
